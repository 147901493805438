import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints } from "@/styles/variables/variables";
import { Fade, Slide, Zoom, useMediaQuery } from "@mui/material";
import { DialogSt } from "./UserDropdown";
import { AlertRetry } from "./loginTabs/AlertRetry";
import { LoginTabs } from "./loginTabs/LoginTabs";
import { Artel, CloseButton, PopupHeader } from "./loginTabs/components";

export const LoginPopup = ({ open, setOpen }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const handleClose = () => setOpen(false);
  return (
    <>
      <DialogSt
        fullScreen={isMobile}
        onClose={handleClose}
        id="user-menu"
        TransitionComponent={isMobile ? Slide : Zoom}
        TransitionProps={{ direction: "up" }}
        open={open}
      >
        {isMobile && (
          <Fade in={true} timeout={700}>
            <PopupHeader>
              <Artel>
                <SvgSelector svg={"logo-artel"} />
              </Artel>
              <CloseButton aria-label="Close" onClick={handleClose}>
                <SvgSelector svg={"close-popup"} />
              </CloseButton>
            </PopupHeader>
          </Fade>
        )}
        <LoginTabs />
      </DialogSt>
      <AlertRetry setOpenPopup={setOpen} />
    </>
  );
};
