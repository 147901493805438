import { Heart } from "./Heart";
import { Slide, useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";
import { PayDialog } from "./components";
import { PayWrap } from "./PayWrap";

export const Payment = ({ open, setOpen }) => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <>
      <Heart open={open} setOpen={setOpen} />
      <PayDialog
        fullScreen={isMobile}
        onClose={() => setOpen(false)}
        id="payment-popup-menu"
        TransitionComponent={Slide}
        TransitionProps={{ direction: isMobile ? "up" : "right" }}
        open={open}
        scroll="paper"
      >
        <PayWrap handleClose={() => setOpen(false)} />
      </PayDialog>
    </>
  );
};
