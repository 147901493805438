import { LoginLabel } from "@/components/header/loginTabs/components";
import SvgSelector from "@/shared/UI/SvgSelector";
import { breakpoints, mixins } from "@/styles/variables/variables";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const errorMessage =
  "Чёт не похоже на электропочту, где-то ошибка, проверьте, попробуйте снова или напишите нам";

export const PayEmailInput = ({
  setDisabled,
  isError,
  inputRef,
  setIsError,
}) => {
  const {
    data: { email },
  } = useSelector((state) => state.user);

  const [value, setValue] = useState(email ?? "");

  const handleDisabled = (e) => {
    const value = e.target.value.trim();
    if (value.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setValue(value);
    setIsError(false);
  };
  
  const resetInput = () => {
    setDisabled(true);
    setValue("");
    setIsError(false);
    inputRef?.current.focus();
  };

  return (
    <>
      <Form noValidate id="email-pay" onSubmit={(e) => e.preventDefault()}>
        <Wr error={isError}>
          <Input
            value={value}
            ref={inputRef}
            autocomplete="off"
            placeholder={"Электропочта"}
            type="email"
            name="email"
            id="email-pay-text"
            required
            maxLength={40}
            pattern="(?=.*@[A-Za-z]+\.)(@|[A-Za-z0-9]|-|_|\.)+"
            onChange={handleDisabled}
          />
          <Icon type="button" disabled={!isError} onClick={resetInput}>
            <SvgSelector
              svg={isError ? "promocode-form-error" : "email-input-def"}
            />
          </Icon>
        </Wr>
      </Form>
      <ErrorLabel>
        {isError ? errorMessage : "Почта, на которую придёт чек об оплате"}
      </ErrorLabel>
    </>
  );
};

const Form = styled.form`
  width: 100%;
`;
const Icon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 100%;
    height: 100%;
  }
  flex-basis: 14.5%;
  flex-shrink: 0;
  @media ${breakpoints.mobile} {
    flex-basis: 22%;
  }
`;
const ErrorLabel = styled(LoginLabel)`
  text-align: left;
  transition: color 0.3s;
`;
const Wr = styled.div`
  display: flex;
  align-items: center;
  background: var(--wh02);
  border-radius: 16px;
  height: 56px;
  @media ${breakpoints.desktopXl} {
    border-radius: 1.25vw;
    height: 4.38vw;
  }
  @media ${breakpoints.tabletL} {
    border-radius: 1.56vw;
    height: 5.47vw;
  }
  @media ${breakpoints.tabletM} {
    border-radius: 2.15vw;
    height: 7.54vw;
  }
  @media ${breakpoints.mobile} {
    border-radius: 5.02vw;
    height: 17.55vw;
    display: flex;
  }
  border: 1.4px solid transparent;
  transition: border-color 0.2s;

  ${({ error }) => error && `border-color: var(--wh12);`};
`;
const Input = styled.input`
  height: 100%;
  flex: auto;
  background-color: transparent;
  padding-left: 1.39vw;
  @media ${breakpoints.desktopXl} {
    padding-left: 1.56vw;
  }
  @media ${breakpoints.tabletL} {
    padding-left: 1.96vw;
  }
  @media ${breakpoints.tabletM} {
    padding-left: 2.69vw;
  }
  @media ${breakpoints.mobile} {
    padding-left: 6.27vw;
    width: 78%;
  }

  ${mixins.fs20}
  line-height: 120%;
  letter-spacing: -0.01em;
  color: var(--wh6);
  &::placeholder {
    color: var(--wh3);
  }
`;
