import { createSlice } from "@reduxjs/toolkit";

const hbCheckedTagsSlice = createSlice({
  name: "hbCheckedTags",
  initialState: {
    checked: [],
    indexOfOverflow: 0,
  },
  reducers: {
    deleteHbCheckedTag(state, action) {
      state.checked = state.checked.filter((el) => el.id !== action.payload.id);
    },
    addHbCheckedTag(state, action) {
      state.checked.push(action.payload);
    },
    resetHbCheckedTags(state) {
      state.checked = [];
    },
    setIndexOfOverflow(state, action) {
      state.indexOfOverflow = Number(action.payload);
    },
  },
});

export const hbCheckedTagsReducer = hbCheckedTagsSlice.reducer;
export const {
  setIndexOfOverflow,
  resetHbCheckedTags,
  addHbCheckedTag,
  deleteHbCheckedTag,
} = hbCheckedTagsSlice.actions;
