import api from "@/services/api";
import SvgSelector from "@/shared/UI/SvgSelector";
import { ROUTES } from "@/shared/constants/routes";
import { breakpoints } from "@/styles/variables/variables";
import { Fade, useMediaQuery } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { siteUrl } from "../../../../../next-sitemap.config";
import { ButtonAccept } from "../../disclaimer/components";
import { FooterEmail, Main } from "../components";
import { PayEmailInput } from "./PayEmailInput";

export const PayEmailContent = () => {
  const isMobile = useMediaQuery(breakpoints.mobile);

  const {
    data: { email },
  } = useSelector((state) => state.user);

  const [disabled, setDisabled] = useState(email ? false : true);

  const [isError, setIsError] = useState(false);

  const inputRef = useRef();

  const handlePayment = (e) => {
    e.preventDefault();

    const email = new FormData(inputRef?.current.form).get("email");

    api
      .post("/api/create-order/", {
        product: "foundation",
        email,
        success_url: siteUrl + ROUTES.THANKS,
      })
      .then(
        (res) => {
          if (res.status === 200) {
            window.location.href = res.data.payment_url;
          }
        },
        (error) => {
          console.log("произошла ошибка оплаты: ", error);
        }
      );
  };

  return (
    <>
      <Fade in={true} timeout={700} style={{ transitionDelay: 100 }}>
        <Main>
          <PayEmailInput
            inputRef={inputRef}
            setDisabled={setDisabled}
            isError={isError}
            setIsError={setIsError}
          />
        </Main>
      </Fade>
      <Fade in={true} timeout={700} style={{ transitionDelay: 150 }}>
        <FooterEmail>
          <ButtonAccept
            disabled={disabled}
            onClick={(e) => {
              if (inputRef?.current.validity.patternMismatch) {
                setIsError(true);
                setDisabled(true);
              } else {
                handlePayment(e);
              }
            }}
            type="button"
            form="email-pay"
          >
            <SvgSelector svg={"button-accept-before"} />
            <SvgSelector svg={`button-accept${isMobile ? "-mobile" : ""}`} />
            <span>К оплате</span>
          </ButtonAccept>
          <p>
            <span>Нажав на кнопку, вы перейдёте на сервис оплаты</span>
            <SvgSelector svg={"tbank"} />
          </p>
        </FooterEmail>
      </Fade>
    </>
  );
};
