import { createSlice } from "@reduxjs/toolkit";

const hbTagsSlice = createSlice({
  name: "hbTags",
  initialState: {
    related: [],
    sections: [],
    popular: [],
    loading: false,
    error: "",
  },
  reducers: {
    fetchHbCuratedTagsSuccess(state, action) {
      state.sections = action.payload.sections;
      state.popular = action.payload.popular;
    },
    fetchHbRelatedTagsSuccess(state, action) {
      state.related = action.payload;
    },
    fetchHbTagsLoading(state, action) {
      state.loading = action.payload;
    },
    fetchHbTagsError(state, action) {
      state.error = action.payload;
    },
  },
});

export const hbTagsReducer = hbTagsSlice.reducer;
export const {
  fetchHbCuratedTagsSuccess,
  fetchHbTagsLoading,
  fetchHbRelatedTagsSuccess,
  fetchHbTagsError,
} = hbTagsSlice.actions;
